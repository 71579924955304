import React from "react"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"


export default function ContactBlock(props) {
  const MyLink = props => <Link to="/contact" {...props} />

  return (

    <section className="contact-block">
    <p className="standout"><span>{props.text}</span></p>
    <Button component={MyLink} variant="contained" to="/contact">Contact me</Button>

    </section>
  );
}
