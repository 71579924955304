import React from "react"
import Slider from "react-slick";

export default function WorkItem(props) {
  const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      pauseOnFocus: true,
      pauseOnHover: true,

    };

  return (
    <section>
    <div>
        <Slider {...settings}>
        { props.image1 &&
            <div>
            <img src={props.image1} alt={props.alt1}/>
            {props.project_link &&
              <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
            }
            </div>
          }

          { props.image2 &&
            <div>
            <img src={props.image2} alt={props.alt2} />
            {props.project_link &&
              <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
            }
            </div>
          }

          { props.image3 &&
            <div>
            <img src={props.image3} alt={props.alt3} />
            {props.project_link &&
              <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
            }
            </div>
          }

          { props.image4 &&
            <div>
            <img src={props.image4} alt={props.alt4} />
            {props.project_link &&
              <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
            }
            </div>
          }

          { props.image5 &&

            <div>
            <img src={props.image5} alt={props.alt5} />
            {props.project_link &&
              <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
            }
            </div>
          }

          { props.image6 &&
            <div>
              <img src={props.image6} alt={props.alt6} />
              {props.project_link &&
                <a className="work-item-button" rel="noreferrer" target="_blank" href={props.project_link}>{props.button_text}</a>
              }
            </div>
          }

        </Slider>
        </div>
        <div>
          <h3>{props.client_name}</h3>
          <p><strong>Services:</strong> {props.project_type}</p>
          { props.worked_with_url &&
            <p><strong>Worked with:</strong> <a rel="noreferrer" target="_blank" href={props.worked_with_url}>{props.worked_with_name}</a></p>
          }
          <p>{props.project_description}</p>
        </div>
      </section>
  );
}
