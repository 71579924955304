import React from "react"

export default function ContentBlock(props) {
  return (
    <section
    id={props.id}
    >
    <h2>{props.header}</h2>
    {props.children}
    </section>
  );
}
