import React from "react"
import Footer from "../components/footer"
import ContentBlock from "../components/contentblock"
import ContactBlock from "../components/contactblock"
import WorkItem from "../components/workitem"

export default function Work() {
  return (

    <main>
<h1>Work Examples</h1>



     <ContentBlock
       id="work">

      </ContentBlock>
<div className="work-items">


      <WorkItem
        image1="/images/work/hc-1.jpg"
        image2="/images/work/hc-2.jpg"
        image3="/images/work/hc-3.jpg"
        image4="/images/work/hc-4.jpg"
        image5="/images/work/hc-5.jpg"
        image6="/images/work/hc-6.jpg"
        alt1=""
        alt2=""
        alt3=""
        alt4=""
        alt5=""
        alt6=""
        client_name="Hastings Contemporary"
        project_type="Complete custom WordPress build, training, improvements, HTML email templates."
        project_description="Hastings Contemporary changed their name and required a rebrand and a whole new website to go along with it, showcasing their exhibitions, and to encourage new people to join as members and support, as well as visit the gallery. As their exhibitions and content change so frequently and the team was small the focus needed to be on a website they could easily update and add new content to all the time. I built them a WordPress site, utilising a range of custom Gutenberg content blocks so they could quickly build pages that had type of content required, as well as templates for exhibitions that they could spin up new ones and publish as required. I also provided support with creating multiple email templates to take advantage of their new branding. Later on, when Covid-19 forced the gallery to close, I came back and helped add some new custom features to allow them to show more of their exhibitions online."
        worked_with_name="Design by Antony Day"
        worked_with_url="https://a-day.co.uk/"
        disabled=""
        project_link="https://hastingscontemporary.org"
        button_text="Visit"
      />

      <WorkItem
        image1="/images/work/fa-1.jpg"
        image2="/images/work/fa-2.jpg"
        image3="/images/work/fa-3.jpg"
        image4="/images/work/fa-4.jpg"
        image5="/images/work/fa-5.jpg"
        alt1=""
        alt2=""
        alt3=""
        alt4=""
        alt5=""
        alt6=""
        client_name="Frontline Aids"
        project_type="Complete custom WordPress build, training, improvements, interactive pieces, and e-commerce."
        project_description="Frontline Aid approached Tilt to create a new website for their rebrand, with simpler IA and colourful engaging pages to spread the message about what Frontline Aids do, collect donations, and share the latest news and resources. They needed an easy-to-use CMS for their constant updates so chose WordPress as they already had experience with this and it provided all the required functionality. The website was then frequently updated with new features and custom interactive pieces for specific campaigns. Unless otherwise stated, the appearance of individuals on these screenshots give no indication of either sexuality or HIV status."
        worked_with_name="UI and UX Design, and development team at Tilt."
        worked_with_url="https://www.wearetilt.com/"
        disabled=""
        project_link="https://frontlineaids.org"
        button_text="Visit"
      />


</div>
      <ContactBlock text="Like what you see?" />

      <Footer icon_credits="Icon credits: Night Mode by Ravindra Kalkani from the Noun Project" />

    </main>
  );
}
